<script setup lang="ts">
import type { EditorialShopTheLookProps } from './EditorialShopTheLook.props'
import { Navigation, Mousewheel, Keyboard, A11y } from 'swiper/modules'

const { isEmporioArmaniWebsite, isEa7Website, isGiorgioArmaniWebsite } =
  useWebsite()

const props = defineProps<EditorialShopTheLookProps>()

const { getProducts } = useShopTheLook()
const products = ref(await getProducts(props.products))
</script>

<template>
  <OrganismsModal :id="id">
    <template #body>
      <div class="grid h-screen grid-cols-12">
        <div
          class="left-side card-container relative col-span-full lg:col-span-6"
          :class="{ 'has-hover': hoverMedia }"
        >
          <div class="card main-media-card w-full lg:col-span-6">
            <UIMedia
              v-bind="media"
              class="h-screen w-full object-cover"
              :aspect-ratio="media.media.type === 'video' ? '5/4' : undefined"
            />
          </div>
          <div
            v-if="hoverMedia"
            class="card hover-image-card absolute top-0 h-screen"
          >
            <UIMedia
              v-bind="hoverMedia"
              class="h-screen w-full object-cover"
              :aspect-ratio="
                hoverMedia.media.type === 'video' ? '5/4' : undefined
              "
            />
          </div>
        </div>
        <div
          class="right-side bg-background-secondary relative col-span-full flex flex-col pb-[var(--navbar-height)] lg:col-span-6 lg:h-full lg:pb-0"
        >
          <div
            class="col-span-full hidden w-full gap-2 p-6 pt-16 text-center lg:absolute lg:left-0 lg:top-0 lg:block"
          >
            <UtilsMarkdown
              :content="title"
              is-preprocessed
              container="span"
              :class="[
                'text-text-primary uppercase',
                isGiorgioArmaniWebsite
                  ? 'md:text-light-3 text-light-4'
                  : 'md:text-medium-3 text-medium-4',
              ]"
            />
            <UtilsMarkdown
              v-if="subtitle"
              :content="subtitle"
              class="text-light-6 md:text-light-5 text-text-primary px-10 lg:block"
            />
          </div>
          <div class="flex items-center lg:h-full lg:py-32">
            <div class="w-full">
              <div class="relative grid w-full grid-cols-12">
                <div
                  class="swiper-button-prev swiper-button-prev-unique z-10 !hidden"
                  :class="{ 'lg:!block': products.length > 3 }"
                />
                <div
                  class="group col-span-full lg:col-span-10 lg:col-start-2 lg:col-end-12"
                  :class="{ 'flex justify-center': products.length === 1 }"
                >
                  <Swiper
                    v-if="products.length > 1"
                    class="swiper swiper-main"
                    :modules="[Navigation, Mousewheel, A11y, Keyboard]"
                    mousewheel
                    keyboard
                    :navigation="{
                      enabled: true,
                      nextEl: '.swiper-button-next-unique',
                      prevEl: '.swiper-button-prev-unique',
                    }"
                    :slides-per-view="1.5"
                    :breakpoints="{
                      768: {
                        slidesPerView: 2.5,
                      },
                      1024: {
                        slidesPerView: 3,
                        simulateTouch: false,
                      },
                    }"
                    grab-cursor
                  >
                    <SwiperSlide
                      v-for="(card, index) in products"
                      :key="index"
                      class="swiper-slide grid-borders-grey"
                      :class="[
                        {
                          'grid-borders-black':
                            isEmporioArmaniWebsite || isEa7Website,
                        },
                        {
                          'ml:first:ml-auto ml:last:mr-auto':
                            products.length === 2,
                        },
                      ]"
                    >
                      <UIProductTile v-bind="card" class="h-full w-full" />
                    </SwiperSlide>
                  </Swiper>

                  <UIProductTile
                    v-if="products.length === 1"
                    v-bind="products[0]"
                    class="max-w-full lg:max-w-[50%]"
                  />
                </div>
                <div
                  class="swiper-button-next swiper-button-next-unique z-10 !hidden"
                  :class="{ 'lg:!block': products.length > 3 }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </OrganismsModal>
</template>

<style scoped lang="scss">
.grid-borders-grey {
  border: 1px solid #d9d9d9;
  grid-gap: 1px;
  background-color: var(--colorStrokeCardDefault);
}

.grid-borders-black {
  border: 1px solid var(--colorStrokeCardHover);
  grid-gap: 1px;
  background-color: var(--colorStrokeCardHover);
}

.card-container {
  .card {
    transition: opacity 300ms ease-in-out;
  }
  .main-media-card {
    opacity: 1;
  }
  .hover-image-card {
    opacity: 0;
  }

  &.has-hover {
    &:hover {
      .main-media-card {
        opacity: 0;
      }
      .hover-image-card {
        opacity: 1;
      }
    }
  }
}

.swiper-button-next-unique::after,
.swiper-button-prev-unique::after,
.swiper-button-next-unique,
.swiper-button-prev-unique {
  --swiper-navigation-size: 18px;
  --swiper-navigation-sides-offset: 30px;
  --swiper-navigation-top-offset: 50%;
  @apply text-text-primary;

  @screen xl {
    --swiper-navigation-sides-offset: 40px;
  }
}
</style>
