<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { Hotspot, InfoHotspot } from '../UI/UIMedia.props'
import type { HotspotModalProps } from './HotspotModal.props'

const props = defineProps<HotspotModalProps>()

const emit = defineEmits(['on-close'])

const { isGiorgioArmaniWebsite } = useWebsite()
const { getProducts } = useShopTheLook()
const indexSelected = ref(props.index)
const productHotspots = ref()
const title = ref(props.productSelected?.title)
const subtitle = ref(props.productSelected?.subtitle)
const getHotspotPosition = (hotspot: Hotspot) => ({
  left: `${hotspot.points.x * 100}%`,
  top: `${hotspot.points.y * 100}%`,
})

const onClick = async (event: Event, hotspot: Hotspot, index: number) => {
  event.preventDefault()
  event.stopPropagation()

  const _data = getObjectBySelector(props?.infoHotspot!, hotspot?.selector)!
  const products = await getProducts([_data?.product!])
  productHotspots.value = products[0].product

  indexSelected.value = index
  title.value = _data.title
  subtitle.value = _data.subtitle
}

const getObjectBySelector = (data: InfoHotspot[], selector: string) => {
  return data.find((item: InfoHotspot) => item.selector === Number(selector))
}

watch(
  props.productSelected,
  async newVal => {
    if (newVal) {
      const product = await getProducts([newVal?.product!])
      productHotspots.value = product?.[0]?.product
    }
  },
  { immediate: true }
)

const { isDialogOpen } = useDialog(SIDEBAR_IDS.hotspotModalProduct)

watch(
  () => isDialogOpen.value,
  isOpen => {
    if (!isOpen) {
      emit('on-close')
    }
  }
)
</script>

<template>
  <OrganismsModal :id="`hotspot-modal-product`" is-hotspot>
    <template #body>
      <div class="mx-auto flex flex-col lg:h-screen lg:flex-row">
        <div
          class="mx-4 my-6 block min-h-[32px] text-left md:mx-6 lg:mx-12 lg:hidden lg:pl-0 lg:text-center xl:mx-16"
        >
          <div
            :class="[isGiorgioArmaniWebsite ? 'text-light-4' : 'text-medium-4']"
          >
            {{ title }}
          </div>
          <div class="text-light-6 hidden lg:block">
            {{ subtitle }}
          </div>
        </div>

        <div class="scrollbar-hide w-full overflow-auto lg:w-1/2">
          <div class="relative">
            <NuxtPicture
              provider="cloudinary"
              :alt="alt"
              :src="src"
              :img-attrs="{
                class: 'w-full lg:min-h-screen lg:object-cover',
              }"
              loading="lazy"
              sizes="sm:100vw"
              class="relative"
            />
            <ClientOnly>
              <div
                v-for="(hotspot, index) in hotspotsProduct"
                :key="hotspot.id"
              >
                <div v-if="hotspot.target === 'product'">
                  <button
                    class="hotspot-button text-book-8"
                    :class="{ 'hotspot-active': indexSelected === index }"
                    :style="getHotspotPosition(hotspot)"
                    :aria-label="$ts('accessibility.hotspotTooltipButton')"
                    @click="onClick($event, hotspot, index)"
                  >
                    {{ index + 1 }}
                  </button>
                </div>
              </div>
            </ClientOnly>
          </div>
        </div>
        <div
          class="bg-background-secondary text-text-primary scrollbar-hide w-auto overflow-auto p-10 pb-[calc(var(--navbar-height)+2px)] text-center md:mx-auto lg:h-auto lg:w-1/2 lg:pb-10"
        >
          <div class="relative h-full w-full">
            <div
              v-if="title || subtitle"
              class="absolute left-0 top-0 hidden w-full lg:block"
            >
              <div class="text-light-4">
                {{ title }}
              </div>
              <div class="text-light-6 hidden lg:block">
                {{ subtitle }}
              </div>
            </div>
            <div
              class="m-auto flex max-w-full items-center justify-center lg:min-h-[100%] lg:max-w-[50%]"
              :class="{ 'lg:py-15': title || subtitle }"
            >
              <ul v-if="productHotspots">
                <NuxtLink :to="productHotspots?.linkToProduct">
                  <UIProductTile
                    v-bind="{ product: productHotspots }"
                    fetch-priority-image
                  />
                </NuxtLink>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
  </OrganismsModal>
</template>

<style lang="scss" scoped>
.hotspot-button {
  @apply bg-neutral-white absolute z-30 flex h-6 w-6 items-center justify-center rounded-full;
  transform: translate(-50%, -50%);
}

.hotspot-active {
  outline: 1px solid white;
  outline-offset: 3px;
}
</style>
